import { Box, Label, List, ListItem, ListItemButton, ListItemText, Typography } from '@coolblue-development/becky';
import React, { useEffect, useState } from 'react';
import { Page } from '../../../models/Page';
import { useAppContext } from '../../../AppContext';
import { IsB2bAgent } from '../../../utils/isB2bAgent';

const Channels = (): JSX.Element => {
  const { setActivePage, activePage, contacts, chatMessages, selectedChat } = useAppContext();

  const menuItems: Page[] = [Page.TELEPHONY, ...(!IsB2bAgent() ? [Page.CHAT] : [])];
  const [totalCount, setTotalCount] = useState<number>(0);

  const handleMenuItemClick = (item: Page): void => {
    setActivePage(item);
  };

  useEffect(() => {
    const totalUnreadCount = contacts.reduce((accumulatedCount, contact) => {
      let unreadCount = parseInt(localStorage.getItem(`unreadMessageCount_${contact.contactId}`) || '0');

      if (selectedChat === contact.contactId && unreadCount > 0) {
        unreadCount = 0;
      }

      return accumulatedCount + unreadCount;
    }, 0);

    setTotalCount(totalUnreadCount);
  }, [contacts, chatMessages, selectedChat]);

  return (
    <>
      <Typography variant="h5" component="h1" sx={{ marginLeft: '25px' }}>
        Channels
      </Typography>
      <List>
        {menuItems.map(item => (
          <ListItem key={item} disablePadding>
            <ListItemButton disableGutters onClick={() => handleMenuItemClick(item)}>
              {activePage === item && (
                <Box
                  sx={{ position: 'absolute', left: '25px', background: '#0090E3', width: '3px', height: '100%' }}
                ></Box>
              )}
              <ListItemText sx={{ marginLeft: '40px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Typography sx={{ lineHeight: 1.25 }} variant={activePage === item ? 'h5' : 'body1'}>
                    {item}
                  </Typography>
                  {item === Page.CHAT && totalCount > 0 && (
                    <Label data-testid="unreadMessageCount" sx={{ marginLeft: '20px' }} color="orange">
                      {totalCount}
                    </Label>
                  )}
                </Box>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default Channels;
