import React, { useEffect, useState } from 'react';
import FlagIcon from '../../../Icons/FlagIcon';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@coolblue-development/becky';
import { countrySelectorUtil } from '../../../../../utils/countrySelectorUtil';
import { countryCodes } from '../../../../../models/Country';
import { Country } from '../../../../../models/Country';
import 'amazon-connect-streams';
import { useAppContext } from '../../../../../AppContext';

interface CountrySelectorProps {
  onCountrySelect: (country: Country) => void;
}

const QuickRepliesCountrySelector: React.FC<CountrySelectorProps> = ({ onCountrySelect }) => {
  const { contacts } = useAppContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (country: Country): void => {
    setSelectedCountry(country);
    onCountrySelect(country);
    handleClose();
  };

  useEffect(() => {
    const defaultCountry = countrySelectorUtil(countryCodes);
    setSelectedCountry(defaultCountry);
    onCountrySelect(defaultCountry);
  }, [onCountrySelect]);

  return (
    <>
      <IconButton
        sx={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          border: '1px solid #DDDDDD',
          background: open ? '#F2F7FC' : 'inherit',
          borderColor: open ? '#0090E3' : '#DDDDDD',
          borderRightColor: open ? '#0090E3' : 'transparent',
          ':hover': { borderColor: '#DDDDDD', borderRightColor: 'transparent' },
          ':disabled': { borderColor: '#DDDDDD', borderRightColor: 'transparent' }
        }}
        preset="text"
        data-testid={`chat-country-selector-button-${selectedCountry?.country ?? ''}`}
        iconSize="large"
        disabled={contacts.length === 0}
        onClick={handleClick}
      >
        {selectedCountry ? <FlagIcon Component={selectedCountry.icon} /> : null}
      </IconButton>
      <Menu
        sx={{
          '& .MuiPaper-root': {
            width: '175px'
          }
        }}
        data-testid="chat-country-selector-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        {countryCodes
          .filter(country => country.label)
          .map(country => (
            <MenuItem key={country.code} onClick={() => handleMenuItemClick(country)}>
              <ListItemIcon>
                <FlagIcon Component={country.icon} />
              </ListItemIcon>
              <ListItemText>{country.label}</ListItemText>
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

export default QuickRepliesCountrySelector;
