import 'amazon-connect-streams';
import 'amazon-connect-chatjs';
import { Backdrop, Box, CircularProgress } from '@mui/material';
import { ContactTypingInfo, chatMessage, useAppContext } from '../../AppContext';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import AuthenticationExpired from '../AuthenticationExpired';
import Contact from '../../models/Contact';
import Header from './Header/Header';
import { Page } from '../../models/Page';
import PageContainer from './Pages/PageContainer';
import SidePanel from './Sidepanel/SidePanel';
import TelephonyStatusBar from './TelephonyStatusBar/TelephonyStatusBar';
import TelephonyWidgetContainer from './Pages/Telephony/Widget/TelephonyWidgetContainer';
import { processAttachment } from '../../utils/processAttachment';
import { addRecentCallsToLocalStorage } from '../../utils/recentCallsUtil';
import DOMPurify from 'dompurify';
import { datadogLogs } from '@datadog/browser-logs';

enum BackdropState {
  None = 0,
  Loading = 1,
  AuthenticationExpired = 2
}

interface CatoContainerProps {
  instanceURL: string;
  samURL: string;
  onViewContact: (contact: Contact) => void;
  region: string;
}

function CatoContainer({ instanceURL, samURL, onViewContact, region }: CatoContainerProps): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null);
  const [backdropState, setBackdropState] = useState(BackdropState.Loading);
  const {
    setAgent,
    setChatMessages,
    activePage,
    setActivePage,
    setContacts,
    setConnectedContacts,
    setLoader,
    setAgentChatSession,
    setAgentVoiceConnection,
    agentChatSession,
    setIsTyping,
    selectedChat,
    setSelectedChat,
    isTelephonyWidgetOpen,
    setIsTelephonyWidgetOpen,
    telephonyStatusBarOpen,
    setTelephonyStatusBarOpen,
    setThirdPartyVoiceConnection,
    setVoiceContact,
    setAgentState,
    setContactsNew
  } = useAppContext();
  const agentChatSessionRef = useRef<{ [key: string]: boolean }>({});
  const chatNotificationAudio = useMemo(
    () => new Audio(`${process.env.REACT_APP_CATO_URL ?? ''}/chatNotification.mp3`),
    []
  );

  useEffect(() => {
    agentChatSession.forEach(chatSession => {
      const contactId = chatSession.getChatDetails().contactId;

      if (!agentChatSessionRef.current[contactId]) {
        chatSession.onMessage(async message => {
          const { ParticipantRole, Content, AbsoluteTime, Id, ContactId, Attachments } = message.data;

          const sanitizedContent = DOMPurify.sanitize(Content || '', { FORBID_TAGS: ['img'] });
          const newMessage: chatMessage = { ParticipantRole, Content: sanitizedContent, AbsoluteTime, Id, ContactId };

          newMessage.attachment = await processAttachment(Attachments, chatSession);

          const lastMessageData = JSON.parse(localStorage.getItem(`lastChat_${contactId}`) || '{}') as {
            lastMessages: string[];
            AbsoluteTime: string;
          };
          let unreadMessageCount = parseInt(localStorage.getItem(`unreadMessageCount_${contactId}`) || '0');
          let lastMessages = [];

          if (Object.keys(lastMessageData).length > 0) {
            lastMessages = lastMessageData.lastMessages;

            lastMessages.push(ParticipantRole ?? '');

            if (lastMessages.length > 2) {
              lastMessages.shift();
            }

            if (lastMessages[0] !== lastMessages[1]) {
              localStorage.setItem(`lastChat_${contactId}`, JSON.stringify({ lastMessages, AbsoluteTime }));
            }
          } else {
            lastMessages.push(ParticipantRole);
            localStorage.setItem(`lastChat_${contactId}`, JSON.stringify({ lastMessages, AbsoluteTime }));
          }

          if (document.hidden) {
            void chatNotificationAudio.play();
          }

          unreadMessageCount++;
          localStorage.setItem(`unreadMessageCount_${contactId}`, unreadMessageCount.toString());
          setIsTyping(prevTypingEvents => prevTypingEvents.filter(item => item.contactId !== ContactId));
          setChatMessages(prevChatMessages => [...prevChatMessages, newMessage]);

          datadogLogs.logger.info('Chat Message Sent', { ContactId, messageId: Id, ParticipantRole: ParticipantRole });
        });

        agentChatSessionRef.current[contactId] = true;
      }
    });
  }, [agentChatSession, chatNotificationAudio, setChatMessages, setIsTyping]);

  useEffect(() => {
    if (selectedChat) {
      onViewContact({ contactId: selectedChat });
      connect.core.viewContact(selectedChat);
    }
  }, [onViewContact, selectedChat]);

  useEffect(() => {
    if (activePage !== Page.CHAT) {
      setSelectedChat(null);
    }
  }, [activePage, setSelectedChat]);

  useEffect(() => {
    connect.agent(agent => {
      const activeContacts = agent.getContacts();
      const contactIds = activeContacts.map(contact => contact.getContactId());
      setContactsNew(contactIds);
    });
  }, [setContactsNew]);

  useLayoutEffect(() => {
    if (containerRef.current) {
      connect.core.initCCP(containerRef.current, {
        ccpUrl: `${instanceURL}/connect/ccp-v2/`,
        loginPopup: true,
        loginOptions: {
          autoClose: true
        },
        loginUrl: samURL,
        ccpLoadTimeout: 3000,
        region: region,
        softphone: {
          allowFramedSoftphone: true
        },
        chat: {
          ringtoneUrl: `${process.env.REACT_APP_CATO_URL ?? ''}/chatRingtone.mp3`
        },
        pageOptions: {
          enableAudioDeviceSettings: true
        }
      });

      connect.core.onIframeRetriesExhausted(() => {
        console.error('onIframeRetriesExhausted');
      });

      connect.core.onViewContact(contact => {
        onViewContact({ contactId: contact.contactId });
      });

      connect.core.onInitialized(() => {
        setBackdropState(BackdropState.None);

        connect.contact(contact => {
          const initialConnection = contact.getActiveInitialConnection();
          const endpoints = initialConnection?.getEndpoint();
          const phoneNumber = endpoints?.phoneNumber;
          const queueName = contact.getQueue().name;

          onViewContact({
            contactId: contact.contactId,
            groupSubsidiary: queueName,
            phoneNumber: phoneNumber
          });
        });
      });
      connect.core.onAuthFail(function () {
        setBackdropState(BackdropState.AuthenticationExpired);
      });
      connect.core.onAccessDenied(function () {
        setBackdropState(BackdropState.AuthenticationExpired);
      });

      connect.agent(function (agent) {
        setAgent(agent);

        agent.onRefresh(() => {
          setAgentState(agent.getState());
        });
      });

      connect.contact(contact => {
        if (contact.getType() !== connect.ContactType.CHAT) {
          setTelephonyStatusBarOpen(true);

          const handleIncomingCall = (): void => {
            setAgentVoiceConnection(contact.getInitialConnection());
            setIsTelephonyWidgetOpen(true);

            setActivePage(Page.TELEPHONY);
            addRecentCallsToLocalStorage(contact);
          };

          contact.onIncoming(() => {
            handleIncomingCall();
          });

          contact.onConnecting(() => {
            handleIncomingCall();
          });

          contact.onConnected(() => {
            setAgentVoiceConnection(contact.getInitialConnection());
          });

          contact.onRefresh(newContact => {
            setVoiceContact(newContact);
            setThirdPartyVoiceConnection(contact.getSingleActiveThirdPartyConnection() as connect.VoiceConnection);
          });

          contact.onDestroy(() => {
            setVoiceContact(null);
            setAgentVoiceConnection(null);
            setThirdPartyVoiceConnection(null);
            setTelephonyStatusBarOpen(false);
            onViewContact({ contactId: '' });
          });

          return;
        }

        contact.onAccepted(async () => {
          updateContacts(contact);
          setLoader(true);
        });

        contact.onACW(() => {
          updateContacts(contact);
          updateContacts(contact, true);
        });

        contact.onConnected(async () => {
          updateContacts(contact);
          updateContacts(contact, true);
          await updateChatSessions(contact);
          setLoader(false);
          setSelectedChat(contact.contactId);
        });

        contact.onConnecting(() => {
          updateContacts(contact);
          setActivePage(Page.CHAT);

          setContactsNew(prevContacts => {
            if (!prevContacts.includes(contact.contactId)) {
              return [...prevContacts, contact.contactId];
            }
            return prevContacts;
          });
        });

        contact.onEnded(() => {
          updateContacts(contact);
        });

        contact.onMissed(() => {
          updateContacts(contact);
        });

        contact.onError(() => {
          updateContacts(contact);
        });

        contact.onDestroy(() => {
          removeContact(contact);
          setContactsNew(prevContacts => prevContacts.filter(contactId => contactId !== contact.contactId));
        });

        async function updateChatSessions(contact: connect.Contact): Promise<void> {
          const chatConnection = contact
            .getConnections()
            .find(cnn => cnn.getType() === connect.ConnectionType.AGENT) as connect.ChatConnection;
          const chatSession = (await chatConnection.getMediaController()) as connect.AgentChatSession;
          const contactId = chatSession.getChatDetails().contactId;
          const existingIndex = agentChatSession.findIndex(
            prevChatSession => prevChatSession.getChatDetails().contactId === contactId
          );

          const contactIdTimeouts: { [contactId: string]: NodeJS.Timeout } = {};

          chatSession.onTyping(event => {
            const { data } = event;
            if (data.ParticipantRole === 'CUSTOMER') {
              const newTypingEvent: ContactTypingInfo = {
                contactId,
                isTyping: true
              };

              setIsTyping(prevTypingEvents => {
                const filteredEvents = prevTypingEvents.filter(item => item.contactId !== contactId);
                return [...filteredEvents, newTypingEvent];
              });

              if (contactIdTimeouts[contactId]) {
                clearTimeout(contactIdTimeouts[contactId]);
              }

              const contactIdTimeout = setTimeout(() => {
                setIsTyping(prevTypingEvents => {
                  return prevTypingEvents.filter(item => item.contactId !== contactId);
                });
              }, 12000);

              contactIdTimeouts[contactId] = contactIdTimeout;
            }
          });

          chatSession.onDeepHeartbeatFailure(() => {
            datadogLogs.logger.info('Heartbeat failure', { ContactId: contactId });
          });

          chatSession.onConnectionBroken(() => {
            datadogLogs.logger.info('Connection Broken', { ContactId: contactId });
          });

          chatSession.onConnectionLost(() => {
            datadogLogs.logger.info('Connection Lost', { ContactId: contactId });
          });

          if (existingIndex === -1) {
            const sessionTranscript = await chatSession.getTranscript({});
            const transcriptResponse = sessionTranscript.data?.Transcript;

            if (transcriptResponse) {
              transcriptResponse.forEach(async message => {
                const { ParticipantRole, Content, AbsoluteTime, Id, Attachments } = message;

                const sanitizedContent = DOMPurify.sanitize(Content || '', { FORBID_TAGS: ['img'] });

                const transcriptMessage: chatMessage = {
                  ParticipantRole,
                  Content: sanitizedContent,
                  AbsoluteTime,
                  Id,
                  ContactId: contactId
                };

                transcriptMessage.attachment = await processAttachment(Attachments, chatSession);
                setChatMessages(prevChatMessages => [...prevChatMessages, transcriptMessage]);
              });
            }
            setAgentChatSession(prevChatSessions => [...prevChatSessions, chatSession]);
          }
        }

        function updateContacts(contact: connect.Contact, isConnectedContacts = false): void {
          const setContactsType = isConnectedContacts ? setConnectedContacts : setContacts;

          setContactsType(prevContacts => {
            const existingIndex = prevContacts.findIndex(prevContact => prevContact === contact);

            if (existingIndex !== -1) {
              prevContacts[existingIndex] = contact;
              return [...prevContacts];
            } else {
              return [...prevContacts, contact];
            }
          });
        }

        function removeContact(contact: connect.Contact): void {
          localStorage.removeItem(`lastChat_${contact.contactId}`);
          setContacts(prevContacts => prevContacts.filter(prevContact => prevContact !== contact));
          setAgentChatSession(prevAgentChatSessions =>
            prevAgentChatSessions.filter(
              prevAgentChatSession => prevAgentChatSession.getChatDetails().contactId !== contact.contactId
            )
          );
          setChatMessages(prevChatMessages =>
            prevChatMessages.filter(prevChatMessage => prevChatMessage.ContactId !== contact.contactId)
          );
          setConnectedContacts(prevConnectedContacts =>
            prevConnectedContacts.filter(prevConnectedContact => prevConnectedContact !== contact)
          );
        }
      });
    }

    // Too many deps to list, needs to be refactored
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instanceURL, samURL, onViewContact]);

  return (
    <div style={{ position: 'relative' }}>
      <Header instanceUrl={instanceURL} />
      {telephonyStatusBarOpen && <TelephonyStatusBar />}
      <Box sx={{ display: 'flex', height: '100vh', background: 'white' }}>
        <SidePanel />
        <PageContainer containerRef={containerRef} />
      </Box>
      {isTelephonyWidgetOpen && <TelephonyWidgetContainer />}
      <Backdrop sx={{ background: '#FFFFFF', position: 'absolute' }} open={backdropState !== BackdropState.None}>
        {backdropState === BackdropState.Loading ? <CircularProgress /> : ''}
        {backdropState === BackdropState.AuthenticationExpired ? <AuthenticationExpired /> : ''}
      </Backdrop>
    </div>
  );
}

export default CatoContainer;
