import { Page } from '../../../models/Page';
import { Box } from '@coolblue-development/becky';
import { useAppContext } from '../../../AppContext';
import Settings from './Settings/Settings';
import Chat from './Chat/Chat';
import TopPanel from '../TopPanel/TopPanel';
import TelephonyPage from './Telephony/Page/TelephonyPage';
import CcpPanel from './CcpPanel';
import { useEffect } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { useFeatureFlags } from '../../ToolkitPanel/useFeatureFlags';
import { FeatureFlag } from '../../constants';
import ChatNew from './Chat/ChatNew';

interface PageContainerProps {
  containerRef?: React.RefObject<HTMLDivElement>;
}

const PageContainer: React.FC<PageContainerProps> = ({ containerRef }) => {
  const { activePage } = useAppContext();
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const isChatRefactorEnabled = isFeatureFlagEnabled(FeatureFlag.CHAT_REFACTOR);

  useEffect(() => {
    datadogLogs.logger.info(`Active page:`, { activePage: activePage });
  }, [activePage]);

  return (
    <Box sx={{ width: '100%' }}>
      <TopPanel />
      {activePage === Page.SETTINGS && (
        <Box sx={{ paddingTop: '25px', marginLeft: '20px' }}>
          <Settings />
        </Box>
      )}
      {activePage === Page.CHAT && !isChatRefactorEnabled && <Chat />}
      {activePage === Page.CHAT && isChatRefactorEnabled && <ChatNew />}
      {activePage === Page.TELEPHONY && <TelephonyPage />}
      {containerRef && <CcpPanel containerRef={containerRef} />}
    </Box>
  );
};

export default PageContainer;
