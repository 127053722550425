import { Box } from '@coolblue-development/becky';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { SpeechBubbleProps } from '../../../../models/SpeechBubbleProps';

const SpeechBubble: React.FC<SpeechBubbleProps> = ({ message, role, timestamp, attachment }) => {
  const isAgent = role !== 'CUSTOMER';
  const bubbleStyle = {
    width: 'fit-content',
    background: isAgent ? '#CCE9F9' : '#FFF',
    borderRadius: '5px',
    padding: '15px',
    marginLeft: isAgent ? 'auto' : '',
    maxWidth: '60%'
  };

  const localTime = new Date(timestamp).toLocaleTimeString(undefined, {
    hour: '2-digit',
    minute: '2-digit'
  });

  const returnImage = (attachment: Blob): JSX.Element => {
    return <img src={URL.createObjectURL(attachment)} style={{ width: '100%', borderRadius: '5px' }} />;
  };

  return message || attachment ? (
    <Box sx={{ marginTop: '10px', textAlign: isAgent ? 'right' : 'left' }} data-testid="speech-bubble">
      <Box
        style={bubbleStyle}
        sx={{ textAlign: 'left', wordBreak: 'break-word' }}
        data-testid={isAgent ? 'agent-bubble' : 'customer-bubble'}
      >
        {message && (
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
              p: ({ children }) => <p style={{ margin: 0 }}>{children}</p>,
              a: ({ ...props }) => <a {...props} target="_blank" />
            }}
          >
            {message}
          </ReactMarkdown>
        )}
        {attachment && returnImage(attachment)}
      </Box>
      {localTime}
    </Box>
  ) : null;
};

export default SpeechBubble;
