import { Box } from '@coolblue-development/becky';
import { useAppContext } from '../../../../AppContext';

const ChatNew = (): JSX.Element => {
  const { telephonyStatusBarOpen } = useAppContext();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        height: '100vh',
        paddingBottom: telephonyStatusBarOpen ? '170px' : '120px'
      }}
    >
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}></Box>
    </Box>
  );
};

export default ChatNew;
